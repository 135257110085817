import React, { Component } from 'react';
import { Link as Ir } from 'react-scroll';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import FontAwesome from 'react-fontawesome'

import Contacto from './Contacto'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  constructor() {
    super()
    this.state = {
      visitas: '',
      show: false,
    }
  }
  componentWillMount () {
    window.H5_loading.show();
    Axios.get(window.ruta+'contador.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({
          visitas: response.data
        })
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
  }
  componentDidMount () {
    window.H5_loading.hide();
  }
  handleShow(id, e){
    window.H5_loading.show();
    this.setState({ show: true });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
    return (
      <div>
        <div className="buzon" onClick={this.handleShow.bind(this)}><img src="https://cdn.hidalgo.gob.mx/componentes/buzon/images/icono_buzon.svg" title="Buzón" alt="Buzón"/></div>
        <div className="pie">
        	<div className="max_width padding_pie at_c">
            <div className="pa_flex_3_p">
              <div className="de_flex_3_p">
              </div>
              <div className="de_flex_3_p2">
                <Link to="../contacto"><img src={`${window.rutas}img/sobre.svg`} className="grises" height="50px" alt="Contacto" title="Contacto"/></Link>
                <div id="cl"></div>
                <div className="flex_redes">
                  {
                    window._my_redes.map((item, i) => 
                      <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                    )
                  }
                </div>
                <div id="cl"><br/></div>
                Camino Real de la Plata No. 305<br/>
                Zona Plateada, Pachuca de Soto, Hidalgo.<br/>
                <a href={`mailto:${window.correo}`} className="correo_pie">{window.correo}</a>
                <div id="cl"><br/></div>
                {window.telefono}
                <div id="cl"><br/><br/></div>
                <h4>VISITANTE {this.state.visitas}</h4>
                <div id="cl"><br/><br/></div>
              </div>
              <div className="de_flex_3_p">
                <div className="linea_vertical"></div>
                <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={1000}>
                  <FontAwesome
                    className="flecha_arriba"
                    name="fal fa-arrow-up"
                    //spin
                  />
                </Ir>
              </div>
            </div>
            <div id="cl"><br/></div>
            <h15>© {ano} Gobierno del Estado de Hidalgo</h15>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro cuadro_sectores" data-aos="fade-down">
            <div className="cuadro_mundo">
              <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
              <Contacto cual={1}/>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default Pie;